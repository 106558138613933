import React from "react";
import "../../../styles/AddMoreFilesBubble.css";
import 'react-tippy/dist/tippy.css'; // Import Tippy.js styles
import 'tippy.js/animations/scale.css'; // Import Tippy.js animation styles
import { Tooltip } from 'react-tippy';

function AddMoreFilesBubble({ addMoreFiles, sortPDFPreviewAZ, sortPDFPreviewZA, merge }) {

    return (
        <div className="principal_div_bubbles" >
            <Tooltip title="Add more PDF's" position="top" animation="scale" arrow size="small">
                <button className="button_add_more_files" onClick={addMoreFiles}/>
            </Tooltip>
        </div>
    );
}

export default AddMoreFilesBubble;