import React, { useState, useEffect } from "react";
import axios from 'axios';
import none_pdf from "../../img/pdf.svg";
import "../../styles/PDFPreview.css";
import 'react-tippy/dist/tippy.css'; // Import Tippy.js styles
import 'tippy.js/animations/scale.css'; // Import Tippy.js animation styles
import { Tooltip } from 'react-tippy';
import Progress from "../../components/Progress"

const API_BASE_URL = ' https://onlysplitpdf-75429c6816ab.herokuapp.com';

function PDFPreview({ id, pdf, pdfName, deletePDFPreview }) {

  const [base64Image, setBase64Image] = useState(null);
  const [numPages, setNumPages] = useState("null");
  const [loadingPercentage, setLoadingPercentage] = useState(0);


  async function imageProccessing(preview) {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/process-image`, {
        pdf_file: pdf,
        preview_img: preview
      },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setLoadingPercentage(progress);
          },
        }
      );
      setNumPages(response.data.pages);
      return response.data.base64_image;
    } catch (error) {
      console.error('Error processing IMAGE:', error);
    }
  }

  useEffect(() => {
    // Define an asynchronous function to fetch the image data
    async function loadImage() {
      const prevFirst = await imageProccessing("0");
      setBase64Image(prevFirst);
    }
    loadImage();
  }, []);

  return (
    <div className="preview_container" style={{cursor: "move"}}>
      {base64Image ?
        <div className="preview_separator">
          <Tooltip className="delete_range_tooltip" title="Remove this PDF" position="top" arrow animation="scale" size="small">
            <div className="delete_range_button" onClick={() => deletePDFPreview(id)} />
          </Tooltip>
          <Tooltip className="preview_separator" title={(pdf.size / 1024 / 1024).toFixed(2) + " MB - " + numPages + " pages"} position="top" animation="scale" arrow>
            <img className="preview_image" src={`data:image/png;base64,${base64Image}`} alt="Pdf Peview" />
            <Tooltip className="preview_separator" title={pdfName} position="bottom" animation="scale" size="small">
              <p>{pdfName}</p>
            </Tooltip>
          </Tooltip>

        </div> : <Progress id="progress" text="Uploading..." loadingPercentage={loadingPercentage.toFixed(2)} />}
    </div>
  );
}

export default PDFPreview;