import React, { useState} from "react";
import "../styles/FAQ.css";

function Question({question, answer, id}) {

    const [isOpen, setIsOpen] = useState(false);

    const clickOpen = () => {
        if(!isOpen){
            document.getElementById("faq_dropdown_content"+id).classList.toggle("show");
            document.getElementById("faq_dropbtn"+id).classList.toggle("minus");
            
        } else{
            document.getElementById("faq_dropdown_content"+id).classList.remove("show");
            document.getElementById("faq_dropbtn"+id).classList.remove("minus");

        }
        setIsOpen(!isOpen);
    }

    return (
        <div className="faq_dropdown">
            <div className="faq_dropdown_showed" onClick={clickOpen}>
                <h2>{question}</h2>
                <button className="faq_dropbtn" id={"faq_dropbtn"+id}></button>
            </div>
            <div className="faq_dropdown_content" id={"faq_dropdown_content"+id}>
                <p>{answer}</p>
            </div>
        </div>
    );
}

export default Question;