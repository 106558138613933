import React, { useState, useRef } from 'react';
import "../styles/FormPDF.css";
import PDFPreview from './PDFPreview';
import axios from 'axios';
import 'react-tippy/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import { Tooltip } from 'react-tippy';
import { v4 as uuidv4 } from "uuid";
import AddMoreFilesBubble from './AddMoreFilesBubble';
import Progress from '../../components/Progress';
import UploadForm from '../../components/UploadForm';
import "../../styles/Dropzone.css";


const API_BASE_URL = 'https://onlysplitpdf-75429c6816ab.herokuapp.com';
const base64_image = [];

function FormPDF(props) {

  
  const title = "Merge PDF's";
  const loading_text = "Merging PDF's...";

  const [mergePDFs, setMergePDFs] = useState();
  const fileInputRef = useRef(null);

  const handlePDFProcessing = async () => {
    const pdfList = pdfPreviewList.map((pdf) => pdf.pdf);

    try {
      const formData = new FormData();
      pdfList.forEach((file, index) => {
        formData.append(`pdf_files[${index}]`, file);
      });
      formData.append('pdf_files_length', pdfList.length.toString());

      const response = await axios.post(`${API_BASE_URL}/api/merge-pdf`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setLoadingPercentage(progress);
          },
          responseType: 'blob',
        });
      console.log(response.data);
      setMergePDFs(response.data);
    } catch (error) {
      console.error('Error processing PDF:', error);
    }
  };

  const [controlProgress, setControlProgress] = useState(false);

  const [fileName, setFileName] = useState("");

  const [controlDropzone, setControlDropzone] = useState(true);

  const [controlButtonReset, setControlButtonReset] = useState(false);

  const [controlSplitting, setControlSplitting] = useState(true);

  const [pages, setPages] = useState([]);

  const [pdf, setPDF] = useState();

  const [pdfPreviewList, setPdfPreviewList] = useState([]);

  const [divZone, setDivZone] = useState(false);

  const [loadingPercentage, setLoadingPercentage] = useState(0);


  function resetDropzone() {
    setControlDropzone(true);
    hideProgress();
    setFileName("");
    hideControlButtonReset();
    setControlSplitting(true);
  };

  function showProgress() {
    setControlProgress(true);
  };

  function hideProgress() {
    setControlProgress(false);
  };

  function showControlButtonReset() {
    setControlButtonReset(true);
  };

  function hideControlButtonReset() {
    setControlButtonReset(false);
  };

  const createPDFPreview = (file) => {
    const key = uuidv4();
    setPdfPreviewList((prevList) => {
      const preview = {
        key: key,
        id: key,
        pdf: file,
        pdfName: file.name
      };
      return [...prevList, preview];
    });
  };

  const fileUploaded = async (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].type === 'application/pdf') {
        fileDropped(e.target.files[i]);
        setFileNoDropped(false);
      }
    }
  }

  const fileDropped = async (pdf_file) => {
    setControlSplitting(false);
    showProgress();
    setPDF(pdf_file);
    createPDFPreview(pdf_file);
    setControlDropzone(false);
    setDivZone(true);
    hideProgress();

  }

  const [fileNoDropped, setFileNoDropped] = useState(true);

  const handleDivClick = async () => {
    document.getElementById("fileInput").click();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if(donwloadControl) return;

    const dropzone = document.getElementById('dropzone');
    dropzone.classList.remove('dragging');
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      if (e.dataTransfer.files[i].type === 'application/pdf') {
        fileDropped(e.dataTransfer.files[i]);
        setFileNoDropped(false);
      }
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    if(donwloadControl) return;

    // Check if files are being dragged
    if (dragging === null) {
      const dropzone = document.getElementById('dropzone');
      dropzone.classList.add('dragging');
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    if(donwloadControl) return;
    const dropzone = document.getElementById('dropzone');
    dropzone.classList.remove('dragging');
  };

  const [merging, setMerging] = useState(false);
  const [donwloadControl, setDonwloadControl] = useState(false);

  const mergePDF = async () => {
    setDivZone(false);
    setMerging(true);
    await handlePDFProcessing().then(() => {
      setMerging(false);
      setDonwloadControl(true);
    });
    console.log("merge");
  }

  const handleReload = () => {
    window.location.reload();
  };

  const clickDownload = () => {
    document.getElementById("download_tag").click();
  };

  const deletePDFPreview = (id) => {
    const new_pdfpreview_list = pdfPreviewList.filter((preview) => preview.key !== id);
    setPdfPreviewList(new_pdfpreview_list);
  };

  const sortPDFPreviewAZ = (id) => {
    const sorted_pdfpreview_list = [...pdfPreviewList].sort((a, b) => a.pdf.name.localeCompare(b.pdf.name));
    setPdfPreviewList(sorted_pdfpreview_list);
    console.log(pdfPreviewList);

  };

  const sortPDFPreviewZA = (id) => {
    const sorted_pdfpreview_list = [...pdfPreviewList].sort((a, b) => b.pdf.name.localeCompare(a.pdf.name));
    setPdfPreviewList(sorted_pdfpreview_list);
    console.log(pdfPreviewList);
  };

  const [dragging, setDragging] = useState(null);

  const handleDragOverPDFPreview = (index) => {
    if (dragging === null) return;
    if (dragging === index) return;

    const new_pdfpreview_list = [...pdfPreviewList];
    const draggingItem = new_pdfpreview_list[dragging];
    new_pdfpreview_list.splice(dragging, 1);
    new_pdfpreview_list.splice(index, 0, draggingItem);

    setPdfPreviewList(new_pdfpreview_list);
    setDragging(index);
  };

  const handleDragStartPDFPreview = (index) => {
    setDragging(index);
  };

  const handleDragEndPDFPreview = () => {
    setDragging(null);
  };

  return (
    <div>
      <div id="dropzone" className="dropzone" onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave}>

        {donwloadControl ?
          <div className="download_pdf">
            <UploadForm
              url="/merge"
              handleDivClick={clickDownload}
              title="Enjoy your PDF file!"
              description="You can download your PDF's merged by clicking on the button below."
              button="Download PDF"
              behindButton=""
            />
            <a
              id="download_tag"
              className="download_tag"
              href={window.URL.createObjectURL(new Blob([mergePDFs], { type: "application/pdf" }))}
              download={"OnCloudPDF_merge.pdf"}
            />
            <a className="reload_tag" href="#" onClick={handleReload}>Continue Merging PDF's</a>
          </div> : null
        }

        {merging && <Progress id="progress" text={loading_text} loadingPercentage={loadingPercentage.toFixed(0)} />}
        {controlSplitting ?
          <UploadForm
            url="/merge"
            handleDivClick={handleDivClick}
            title="Merge PDF's"
            description="Merge mutiple PDF's online for free with our easy-to-use tool. Combine multiple PDF files seamlessly and efficiently."
            button="Select PDF Files"
            behindButton="or drop PDF files here"
          />
          : null}
        <input
          id="fileInput"
          type="file"
          accept=".pdf"
          ref={fileInputRef}
          onChange={fileUploaded}
          hidden
          multiple
        />

        {divZone ?
          <div className='div_preview'>
            <div className="pdf_preview_list_title">
              <div className='pdf_preview_title'>
                <h1>{title}</h1>
              </div>
              <div className='pdf_preview_list'>
                {pdfPreviewList.map((newPDFPreview, index) => (
                  <div
                    key={newPDFPreview.key}
                    draggable
                    onDragStart={() => handleDragStartPDFPreview(index)}
                    onDragOver={() => handleDragOverPDFPreview(index)}
                    onDragEnd={handleDragEndPDFPreview}
                  >
                    <PDFPreview
                      id={newPDFPreview.key}
                      pdf={newPDFPreview.pdf}
                      pdfName={newPDFPreview.pdfName}
                      deletePDFPreview={deletePDFPreview}
                    />
                  </div>
                ))}
              </div>
            </div>
            <AddMoreFilesBubble
              addMoreFiles={handleDivClick}
              sortPDFPreviewAZ={sortPDFPreviewAZ}
              sortPDFPreviewZA={sortPDFPreviewZA}
              merge={mergePDF} />
          </div> : null}
      </div>
    </div>
  );
}

export default FormPDF;