import React from "react";
import '../styles/SelectToolButton.css';


function SelectToolButton({label, source, altText, link}) {

    const goToLink = () => {
        window.open(link, '_blank');
    };

    return (
        <div className="select_tool_button" onClick={goToLink}>
            <img className="image" src={source} alt={altText}></img>
            <label className="label">{label}</label>
        </div>
    );
}

export default SelectToolButton;