import React, { useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import '../styles/Main.css';
import OrganizeRow from './OrganizeRow';
import FromPDFRow from './FromPDFRow';
import ExplainMain from './ExplainMain';
import Others from './Others';

function Main() {


    return (
        <div>
            <title>Your Ultimate Free PDF Management Solution | OnCloudPDF </title>
            <Header />
            <ExplainMain />
            <div className='select_tool'>
                <OrganizeRow />
                <FromPDFRow />
                <Others />
            </div>
            <Footer />
        </div >
    );

}

export default Main;