import React, { useState } from "react";
import UploadForm from "../../components/UploadForm";
import Progress from "../../components/Progress";
import { v4 as uuidv4 } from "uuid";
import PDFPreview from "./PDFPreview";
import AddMoreFilesBubble from "../../components/AddMoreFilesBubble";
import "../../styles/FormPDF.css";
import axios from "axios";
import "../../styles/LateralZone.css"


const API_BASE_URL = 'https://onlysplitpdf-75429c6816ab.herokuapp.com';

function FormPDF() {

    const title = "Rotate PDF's'";
    const loading_text = "Rotating PDF Files...";

    const [converting, setConverting] = useState(false);
    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const [fileIsDropped, setFileIsDropped] = useState(false);
    const [pdfPreviewList, setPdfPreviewList] = useState([]);
    const [showFormPDF, setShowFormPDF] = useState(false);
    const [showDownload, setShowDownload] = useState(false);
    const [PDFConverted, setPDFConverted] = useState();

    const handleDivClick = async () => {
        document.getElementById("fileInput_rotate").click();
    };

    const createPDFPreview = (file) => {
        const key = uuidv4();
        setPdfPreviewList((prevList) => {
            const preview = {
                key: key,
                id: key,
                pdf: file,
                pdfName: file.name,
                rotate: 0,
            };
            return [...prevList, preview];
        });
    };

    const fileDropped = async (pdf_file) => {
        createPDFPreview(pdf_file);
    }

    const fileUploaded = async (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            if (e.target.files[i].type === 'application/pdf') {
                fileDropped(e.target.files[i]);
                setFileIsDropped(true);
                setShowFormPDF(true);
            }
        }
    }

    const handleDrop = (e) => {
        e.preventDefault();
        if(showDownload) return;
        const dropzone = document.getElementById('dropzone_rotate');
        dropzone.classList.remove('dragging');
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
            if (e.dataTransfer.files[i].type === 'application/pdf') {
                fileDropped(e.dataTransfer.files[i]);
                setFileIsDropped(true);
                setShowFormPDF(true);
            }
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        if(showDownload) return;
        const dropzone = document.getElementById('dropzone_rotate');
        dropzone.classList.add('dragging');
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        if(showDownload) return;
        const dropzone = document.getElementById('dropzone_rotate');
        dropzone.classList.remove('dragging');
    };

    const deletePDFPreview = (id) => {
        const new_pdfpreview_list = pdfPreviewList.filter((preview) => preview.key !== id);
        setPdfPreviewList(new_pdfpreview_list);
    };

    const rotatePDFPreview = (id) => {
        const new_pdfpreview_list = pdfPreviewList.map((preview) => {
            if (preview.key === id) {
                if(preview.rotate === 270){
                    preview.rotate = 0;
                    return preview;
                }else{
                    preview.rotate = preview.rotate + 90;
                }
            }
            return preview;
        });
        setPdfPreviewList(new_pdfpreview_list);
    };

    const convertPDF = async () => {
        setShowFormPDF(false);
        setConverting(true);
        await handlePDFProcessing().then(() => {
            setConverting(false);
            setShowDownload(true);
        });
    }

    const handlePDFProcessing = async () => {

        try {
            const formData = new FormData();
            pdfPreviewList.map((pdf, index) => {
                formData.append(`pdf_files[${index}]`, pdf.pdf);
                formData.append(`pdf_files_name[${index}]`, pdf.pdfName.split('.')[0]);
                formData.append(`pdf_file_deg[${index}]`, pdf.rotate);
            });
            formData.append('pdf_files_length', pdfPreviewList.length.toString());

            const response = await axios.post(`${API_BASE_URL}/api/rotate`, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const progress = (progressEvent.loaded / progressEvent.total) * 100;
                        setLoadingPercentage(progress);
                    },
                    responseType: 'blob',
                });
            setPDFConverted(response.data);
        } catch (error) {
            console.error('Error processing PDF:', error);
        }
    };

    const clickDownload = () => {
        document.getElementById("download_tag").click();
    };

    const handleReload = () => {
        window.location.reload();
    };

    return (
        <div>
            <div id="dropzone_rotate" className="dropzone" onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragLeave}>

                {showDownload ?
                    <div className="download_pdf">
                        <UploadForm
                            url="/merge"
                            handleDivClick={clickDownload}
                            title="Enjoy your PDF file!"
                            description="You can download your rotated PDF Files by clicking on the button below."
                            button="Download Rotated PDF's"
                            behindButton=""
                        />
                        <a
                            id="download_tag"
                            className="download_tag"
                            href={window.URL.createObjectURL(new Blob([PDFConverted], { type: "application/zip" }))}
                            download={"on_cloud_pdf.zip"}
                        />
                        <a className="reload_tag" href="#" onClick={handleReload}>Continue Rotating PDF's</a>
                    </div>
                    : null
                }

                {converting && <Progress text={loading_text} loadingPercentage={loadingPercentage.toFixed(0)} />}
                {fileIsDropped ? null :
                    <UploadForm
                        url="/rotate"
                        handleDivClick={handleDivClick}
                        title={title}
                        description="Transform your document workflow effortlessly with our online PDF rotation tool. Seamlessly rotate PDF files for optimal viewing and collaboration. Experience unmatched convenience and precision as you effortlessly adjust document orientation. Elevate your document handling with our user-friendly PDF rotation tool, ensuring hassle-free and precise adjustments."
                        button="Select PDF Files"
                        behindButton="or drop PDF files here" />}

                <input
                    id="fileInput_rotate"
                    type="file"
                    accept=".pdf"
                    onChange={fileUploaded}
                    hidden
                    multiple
                />

                {showFormPDF ?
                    <div className='div_preview'>
                        <div className="pdf_preview_list_title">
                            <div className='pdf_preview_title'>
                                <h1>{title}</h1>
                            </div>
                            <div className='pdf_preview_list'>
                                {pdfPreviewList.map((newPDFPreview) =>
                                    <div onDragStart={(e) => e.preventDefault()}>
                                        <PDFPreview
                                            key={newPDFPreview.key}
                                            id={newPDFPreview.key}
                                            pdf={newPDFPreview.pdf}
                                            pdfName={newPDFPreview.pdfName}
                                            rotate={newPDFPreview.rotate}
                                            deletePDFPreview={deletePDFPreview}
                                            rotatePDFPreview={rotatePDFPreview}
                                        />
                                    </div>
                                )}
                            </div>

                        </div>

                        <div className="lateral_div">
                            <AddMoreFilesBubble
                                addMoreFiles={handleDivClick} />

                            <button className="convert_button" onClick={convertPDF}>Rotate PDF's</button>
                        </div>

                    </div>
                    : null}
            </div>
        </div>
    );
}

export default FormPDF;
