import React from "react";
import "../styles/ExplainMain.css";

function ExplainMain() {
    return (
        <div className="explain_div_father">
            <div className="explain_div">
                <h1>🚀 Welcome to OnCloudPDF - Unleash the Power of PDFs! 🚀</h1>
                <h3>Transform the way you handle PDFs with OnCloudPDF – your all-in-one solution for seamless document management and collaboration. Bid farewell to the hassle of handling PDF files and embrace a new era of efficiency and productivity, all 100% free to use!</h3>
            </div>
        </div>
    );
}

export default ExplainMain;
