import React from "react";

import "../../styles/LegalCookies.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";


function Legal() {
    return (
        <div>
            <title>Legal Notice - OnCloudPDF</title>
            <Header />
            <div className="legal_cookies_div_father">
                <div className="legal_cookies_div">
                    <h1>Legal Notice</h1>
                    <h2>Identification of the Website Owner</h2>
                    <p>In compliance with the information duty provided in Article 10 of Law 34/2002, of July 11, on Information Society Services and Electronic Commerce, the following details are provided:</p>
                    <p>Company Name: OnCloudPDF S.L.</p>
                    <p>Email: ttl.developers.apps@gmail.com</p>
                    <p>Location: Las Palmas de Gran Canaria, C/ Escritor Secundino Delgado, 14</p>
                    <p>Activity: Software Development</p>
                    <p>Fiscal Identification: 45397844F</p>

                    <h2>Intellectual and Industrial Property</h2>
                    <p>OnCloudPDF S.L. holds all intellectual and industrial property rights for its website, as well as its contents (texts, images, designs, logos, source code, etc.).</p>

                    <h2>Responsibility</h2>
                    <p>OnCloudPDF S.L. disclaims any liability for the information published on its website, provided that such information has been tampered with or introduced by a third party unrelated to the company.</p>

                    <h2>Applicable Law and Jurisdiction</h2>
                    <p>The relationship between OnCloudPDF S.L. and the user will be governed by current Spanish regulations, and any dispute will be submitted to the courts and tribunals of the city of Valencia, unless the law prescribes another jurisdiction.</p>

                    <h2>Contact</h2>
                    <p>For any inquiries or comments regarding our legal notice, please contact us at the following email address: ttl.developers.apps@gmail.com</p>

                    <p>Thank you for visiting OnCloudPDF S.L.</p>

                </div>
            </div>
            <Footer />
        </div>
    );
}


export default Legal;