import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import FormPDF from "./FormPDF";


function Rotate() {

    return (
        <div className="App">
            <title>Rotate PDF Pages for Free - Quick and Simple PDF Rotation Tool</title>
            <Header />
            <FormPDF />
            <Footer />
        </div>
    );
}

export default Rotate;