import React, { useState, useEffect } from "react";
import "../styles/SelectPropertiesPDF.css";
import plus from "../../img/plus.svg";
import { v4 as uuidv4 } from "uuid";
import cross_delete from "../../img/cross.svg";
import { Tooltip } from "react-tippy";
import "../../styles/LateralZone.css"

function SelectPropertiesPDF({ pages, createPDFPreview, updatePDFPreviewFromValue, updatePDFPreviewToValue, updatePDFPreviewBothValues, splitClick, deletAllPDFPreview, deletePDFPreview }) {

	const lengthPages = pages.length;
	var control = 0;
	useEffect(() => {
		if (control === 0) {
			control++;
			setFixedValue(1);
			addDivisionsList(); // Llama a la función cuando se monta el componente
			const custom_ranges = document.getElementById("custom_ranges");
			custom_ranges.classList.add("active");
		}
	}, []);

	const [divisionsList, setDivisionsList] = useState([]);
	const [fixedDivisionsList, setFixedDivisionsList] = useState([]);

	const [fixed_value, setFixedValue] = useState(null);

	const updateFixedDivisionsList = (value) => {
		if (Number(value) > 0 && Number.isInteger(Number(value))) {
			setFixedDivisionsList(fixedDivisionsList.filter((preview) => null));
			deletAllPDFPreview();
			for (let i = 1; i <= lengthPages; i += Number(value)) {
				if (i + Number(value) > lengthPages) {
					addDivisionsFixedList(i, lengthPages);
				} else {
					addDivisionsFixedList(i, Number(value) + i - 1);
				}
			}
		}
	}

	const updateFixedDivisionsListOddEven = (control) => {
		setFixedDivisionsList(fixedDivisionsList.filter((preview) => null));
		deletAllPDFPreview();
		for (let i = control; i <= lengthPages; i += 2) {
			addDivisionsFixedList(i, i);
		}
	}

	const addDivisionsFixedList = (i, division_num) => {
		const key = uuidv4();
		var fromValue = i;
		var toValue = division_num;

		setFixedDivisionsList((prevList) => {
			const preview = {
				key: key,
				fromValue: fromValue,
				toValue: toValue,
			};
			return [...prevList, preview];
		});

		// createPDFPreviewFixed(key, fromValue - 1, toValue - 1);
		createPDFPreview(key, fromValue - 1, toValue - 1);
	};

	const handleUpdateList = (e) => {
		const value = e.target.value;
		if (value !== "") {
			setFixedValue(value);
			updateFixedDivisionsList(value);
		} else {
			setFixedValue(value);
		}
	};

	const getLastToValue = () => {
		const newDivisionsList = divisionsList[divisionsList.length - 1].toValue;
		if (newDivisionsList < 1) {
			return 1;
		} else if (newDivisionsList > lengthPages) {
			return lengthPages;
		}
		return newDivisionsList;
	};

	const getLastRange = () => {
		const newDivisionsList = divisionsList[divisionsList.length - 1].range;
		return newDivisionsList + 1;
	};

	const updateDivisionsList = () => {
		divisionsList.map((division) => {
			createPDFPreview(division.key, division.fromValue - 1, division.toValue - 1);
		});
	};

	const addDivisionsList = () => {
		const key = uuidv4();
		var range = null;
		var fromValue = null;
		try {
			range = getLastRange();
			fromValue = getLastToValue();
		} catch {
			range = 1;
			fromValue = 1;
		}

		const division = {
			range: range,
			key: key,
			fromValue: fromValue, // Initialize fromValue for the new division
			toValue: lengthPages, // Initialize toValue for the new division
		};
		const new_division_list = [...divisionsList, division];
		setDivisionsList(new_division_list);
		createPDFPreview(key, fromValue - 1, lengthPages - 1);
	};

	const handleFromChange = (id, e) => {
		const newDivisionsList = divisionsList.map((division) => {
			if (division.key === id) {
				if (e.target.value >= division.toValue) {
					updatePDFPreviewBothValues(id, e.target.value, e.target.value);
					return {
						...division,
						fromValue: parseInt(e.target.value),
						toValue: parseInt(e.target.value),
					};
				}
				updatePDFPreviewFromValue(id, e.target.value)
				return { ...division, fromValue: e.target.value };
			}
			return division;
		});
		setDivisionsList(newDivisionsList);
	};

	// Función para manejar cambios en el valor "To"
	const handleToChange = (id, e) => {
		const newDivisionsList = divisionsList.map((division) => {

			if (division.key === id) {
				if (e.target.value <= division.fromValue) {
					updatePDFPreviewBothValues(id, e.target.value, e.target.value);
					return {
						...division,
						toValue: parseInt(e.target.value),
						fromValue: parseInt(e.target.value),
					};
				}
				updatePDFPreviewToValue(id, e.target.value);
				return { ...division, toValue: parseInt(e.target.value) };
			}
			return division;
		});
		if (e.target.value !== "") {
			setDivisionsList(newDivisionsList);
		}

	};

	const deletePDFRangeAndPreview = (id) => {
		const newDivisionsList = divisionsList.filter((division) => {
			return division.key !== id;
		});
		setDivisionsList(newDivisionsList);
		deletePDFPreview(id);
	};

	const getExactDivision = () => {
		if (rangesButton === 1) {
			return splitClick(fixedDivisionsList);
		} else {
			return splitClick(divisionsList);
		}
	}

	const [rangesButton, setRangesButton] = useState(0);

	const handleButtonRanges = (e) => {
		const custom_ranges = document.getElementById("custom_ranges");
		const fixed_ranges = document.getElementById("fixed_ranges");
		if (e.target.id === "custom_ranges") {
			deletAllPDFPreview();
			updateDivisionsList();
			setRangesButton(0);
			custom_ranges.classList.add("active");
			fixed_ranges.classList.remove("active");
		} else {
			deletAllPDFPreview();
			updateFixedDivisionsList(fixed_value);
			setRangesButton(1);
			custom_ranges.classList.remove("active");
			fixed_ranges.classList.add("active");
		}
	};

	function Division({
		range,
		id,
		fromValue,
		toValue,
		onFromChange,
		onToChange,
	}) {

		const [fromValueState, setFromValueState] = useState(fromValue);

		const handleKeyPressFrom = (e) => {
			e.preventDefault();
			setFromValueState(e.target.value);
			if (Number(fromValueState) !== 0) {
				if (Number(e.target.value) === Number(fromValueState) + 1 || Number(e.target.value) === Number(fromValueState) - 1) {
					if (Number(e.target.value) > lengthPages || Number(e.target.value) < 1) {
						const cero = 0;
					} else {
						onFromChange(e);
					}
				}
			}
		};

		const onKeyEnterPressFrom = (e) => {
			if (e.key === 'Enter') {
				e.target.blur();
			}
		};

		const onBlurSetFromValueFrom = async (e) => {

			if (!isNaN(fromValueState)) {
				if (fromValueState <= 0) {
					await waitAwaitFrom(1).then(() => {
						onFromChange(e);
					});
				} else if (fromValueState > lengthPages) {
					await waitAwaitFrom(lengthPages).then(() => {
						onFromChange(e);
					});
				} else {
					onFromChange(e);
				}
			} else {
				await waitAwaitFrom(1).then(() => {
					onFromChange(e);
				});
			}
		};

		const waitAwaitFrom = async (number) => {
			setFromValueState(number);
		};

		const [toValueState, setToValueState] = useState(toValue);

		const handleKeyPressTo = (e) => {
			e.preventDefault();
			setToValueState(e.target.value);
			if (Number(toValueState) !== 0) {
				if (Number(e.target.value) === Number(toValueState) + 1 || Number(e.target.value) === Number(toValueState) - 1) {
					if (Number(e.target.value) > lengthPages || Number(e.target.value) < 1) {
						const cero = 0;
					} else {
						onToChange(e);
					}
				}
			}

		};

		const onKeyEnterPressTo = (e) => {
			if (e.key === 'Enter') {
				e.target.blur();
			}
		};

		const onBlurSetFromValueTo = async (e) => {

			if (!isNaN(toValueState)) {
				if (toValueState <= 0) {
					await waitAwaitTo(1).then(() => {
						onToChange(e);
					});
				} else if (toValueState > lengthPages) {
					await waitAwaitTo(lengthPages).then(() => {
						onToChange(e);
					});
				} else {
					onToChange(e);
				}
			} else {
				await waitAwaitTo(1).then(() => {
					onToChange(e);
				});
			}
		};

		const waitAwaitTo = async (number) => {
			setToValueState(number);
		};

		return (
			<div className="division_container">
				<div className="for_range">
					<p>Range {range}</p>
				</div>
				<button className="delete_range_button" onClick={() => deletePDFRangeAndPreview(id)}>
					<Tooltip title="Delete Range" position="top" animation="scale" arrow size="small" distance={25}>
						<img className="delete_img" src={cross_delete} alt={"Delete Range " + range} />
					</Tooltip>
				</button>
				<div className="from_to_container">
					<div className="from_to">
						<div className="label_from_to">From</div>
						<div className="input_from_to">
							<input
								id={id + "from"}
								type="number"
								min="1"
								max={lengthPages}
								value={fromValueState}
								onChange={handleKeyPressFrom}
								onKeyDown={onKeyEnterPressFrom}
								onBlur={onBlurSetFromValueFrom}
							/>
						</div>
					</div>
					<div className="from_to">
						<div className="label_from_to">To</div>
						<div className="input_from_to">
							<input
								id={id + "to"}
								type="number"
								min="1"
								max={lengthPages}
								value={toValueState}
								onChange={handleKeyPressTo}
								onKeyDown={onKeyEnterPressTo}
								onBlur={onBlurSetFromValueTo}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	const [isCheckedCustom, setIsCheckedCustom] = useState(true);
	const [isCheckedOdd, setIsCheckedOdd] = useState(false);
	const [isCheckedEven, setIsCheckedEven] = useState(false);
	const [isCheckedEdit, setIsCheckedEdit] = useState(false);

	const handleCheckboxCustom = () => {
		setIsCheckedCustom(!isCheckedCustom);
		updateFixedDivisionsList(fixed_value);
		if (!isCheckedCustom) {
			setIsCheckedOdd(isCheckedCustom);
			setIsCheckedEven(isCheckedCustom);
			setIsCheckedEdit(isCheckedCustom);
		}
		setInputRangeText("1,2-3,5,...");
	};
	const handleCheckboxOdd = () => {
		updateFixedDivisionsListOddEven(1);
		setIsCheckedOdd(!isCheckedOdd);
		if (!isCheckedOdd) {
			setIsCheckedCustom(isCheckedOdd);
			setIsCheckedEven(isCheckedOdd);
			setIsCheckedEdit(isCheckedOdd);
		}
		setInputRangeText("1,2-3,5,...");
	};

	const handleCheckboxEven = () => {
		updateFixedDivisionsListOddEven(2);
		setIsCheckedEven(!isCheckedEven);
		if (!isCheckedEven) {
			setIsCheckedCustom(isCheckedEven);
			setIsCheckedOdd(isCheckedEven);
			setIsCheckedEdit(isCheckedEven);
		}
		setInputRangeText("1,2-3,5,...");
	};

	const handleCheckboxEdit = () => {
		setIsCheckedEdit(!isCheckedEdit);
		if (!isCheckedEdit) {
			setIsCheckedCustom(isCheckedEdit);
			setIsCheckedOdd(isCheckedEdit);
			setIsCheckedEven(isCheckedEdit);
		}
		deletAllPDFPreview();
	};

	const clearHintText = (e) => {
		if (e.target.value === "1,2-3,5,...") {
			setInputRangeText("");
			setFixedDivisionsList(fixedDivisionsList.filter((preview) => null));
			deletAllPDFPreview();
		}
	}

	const catchText = (e) => {
		const proccessedValue = e.target.value.replace(" ", "")
		setInputRangeText(proccessedValue);
		if (checkToPrint(proccessedValue)) {
			proccesTextPDFPreview(proccessedValue);
			setHasError(false);
		} else {
			setHasError(true);
		}
	}

	const proccesTextPDFPreview = (value) => {
		setFixedDivisionsList(fixedDivisionsList.filter((preview) => null));
		deletAllPDFPreview();
		const parts = value.split(',');
		for (const part of parts) {
			if (part.includes('-')) {
				const [start, end] = part.split('-');
				addDivisionsFixedList(parseInt(start), parseInt(end));
			} else {
				addDivisionsFixedList(parseInt(part), parseInt(part));
			}
		}
	}

	const checkToPrint = (value) => {
		const regex = /^[0-9,-]*$/;
		if (!regex.test(value)) {
			return false;
		}
		const parts = value.split(',');
		for (const part of parts) {
			if (part.includes('-')) {
				const [start, end] = part.split('-');
				if (part.split('-').length > 2) {
					return false; // No es un número válido
				}
				if (start > lengthPages || end > lengthPages) {
					return false; // El primer número debe ser mayor que el segundo en un rango
				}
				if (end === "") {
					return false; // No es un número válido
				}
				if (parseInt(start) >= parseInt(end)) {
					return false; // El primer número debe ser mayor que el segundo en un rango
				}
				if (start === "") {
					return false; // El primer número debe valido
				}
			} else {
				if (parseInt(part) > lengthPages) {
					return false; // El primer número debe ser mayor que el segundo en un rango
				}
				if (isNaN(parseInt(part))) {
					return false; // No es un número válido
				}
			}
		}
		return true;
	}

	const [inputRangeText, setInputRangeText] = useState("1,2-3,5,...");
	const [hasError, setHasError] = useState(false);

	return (
		<div className="lateral_div">
			<div className="select_ranges">
				<button id="custom_ranges" className="button_ranges" onClick={handleButtonRanges}>Custom Ranges</button>
				<button id="fixed_ranges" className="button_ranges" onClick={handleButtonRanges}>Fixed Ranges</button>
			</div>

			{rangesButton === 1 ?
				<div className="container_fixed">
					<label className="label_check">
						<input
							className="checkbox"
							type="checkbox"
							checked={isCheckedCustom}
							onChange={handleCheckboxCustom}
						/>
						Custom Fixed Ranges
					</label>
					{isCheckedCustom ? <div className="container_fixed_ranges">
						<p>Split in ranges of</p>
						<input
							className="input_fixed_ranges"
							type="number"
							min="1"
							value={fixed_value}
							max={lengthPages}
							onChange={(e) => handleUpdateList(e)}
							onInput={(e) => handleUpdateList(e)}
						/>
					</div> : null}
					<label className="label_check">
						<input
							className="checkbox"
							type="checkbox"
							checked={isCheckedOdd}
							onChange={handleCheckboxOdd}
						/>
						Only Odd Pages
					</label>
					<label className="label_check">
						<input
							className="checkbox"
							type="checkbox"
							checked={isCheckedEven}
							onChange={handleCheckboxEven}
						/>
						Only Even Pages
					</label>

					<label className="label_check">
						<input
							className="checkbox"
							type="checkbox"
							checked={isCheckedEdit}
							onChange={handleCheckboxEdit}
						/>
						Edit Your Ranges
					</label>
					{isCheckedEdit ?
						<div className="has_error">
							<div className="container_fixed_ranges">
								<p>Split in ranges of</p>
								<input className={hasError ? 'error-input' : "input_ranges"} type="text" id="hintedInput" contentEditable="true" value={inputRangeText} onFocus={clearHintText} onInput={catchText}></input>

							</div>
							{hasError ? <div className="error-message">Please enter a valid value.</div> : null}
						</div>
						: null}
				</div> :
				<div className="select_container">
					{divisionsList.map((newDivision) => (
						<Division
							range={newDivision.range}
							key={newDivision.key}
							id={newDivision.key}
							fromValue={newDivision.fromValue}
							toValue={newDivision.toValue}
							onFromChange={(e) => handleFromChange(newDivision.key, e)}
							onToChange={(e) => handleToChange(newDivision.key, e)}
						/>
					))}
					<button className="add_new_division" onClick={addDivisionsList}>
						<img
							className="img_plus"
							src={plus}
							alt="Texto alternativo de la imagen"
						/>
						<div>Add Division</div>
					</button>
				</div>}
			<div className="div_split">
				<button className="split_button" onClick={getExactDivision}>Split</button>
			</div>
		</div>
	);
}

export default SelectPropertiesPDF;
