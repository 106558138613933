import React, { useState, useEffect } from "react";
import axios from 'axios';
import "../../styles/PDFPreview.css";
import Progress from "../../components/Progress";

const API_BASE_URL = ' https://onlysplitpdf-75429c6816ab.herokuapp.com';

function PDFPreview({ id, pdf, previewFirst, previewSecond, savePdfBase64, getPdfBase64 }) {

  const [base64ImageFirst, setBase64ImageFirst] = useState(null);
  const [base64ImageSecond, setBase64ImageSecond] = useState(null);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  const updatePDFPreviewImage = async () => {
    if (previewFirst === previewSecond) {
      const prevFirst = await imageProccessing(previewFirst);
      setBase64ImageFirst(prevFirst);
    } else {
      const prevFirst = await imageProccessing(previewFirst);
      const prevSec = await imageProccessing(previewSecond);
      setBase64ImageFirst(prevFirst);
      setBase64ImageSecond(prevSec);
    }
  }

  async function imageProccessing(preview) {
    const pdf_image = getPdfBase64(preview);
    if (pdf_image) {
      return pdf_image;
    }
    try {
      const response = await axios.post(`${API_BASE_URL}/api/process-image`, {
        pdf_file: pdf,
        preview_img: preview
      },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setLoadingPercentage(progress);
          },
        });
      savePdfBase64(response.data.base64_image, preview);
      return response.data.base64_image;
    } catch (error) {
      console.error('Error processing IMAGE:', error);
    }
  }

  useEffect(() => {
    async function loadImage() {
      if (previewFirst === previewSecond) {
        const prevFirst = await imageProccessing(previewFirst);
        setBase64ImageFirst(prevFirst);
        setBase64ImageSecond(null);
      } else {
        const prevFirst = await imageProccessing(previewFirst);
        const prevSec = await imageProccessing(previewSecond);
        setBase64ImageFirst(prevFirst);
        setBase64ImageSecond(prevSec);
      }
    }
    loadImage();
  }, [previewFirst, previewSecond]);

  return (
    <div className="preview_container">
      <p className="p_preview" onChange={updatePDFPreviewImage}>Page {previewFirst + 1} to {previewSecond + 1}</p>
      <div className="preview_separator">
        <div className="preview_separator_line">
          {base64ImageFirst ? <img className="preview_image" src={`data:image/png;base64,${base64ImageFirst}`} alt="Pdf Peview First of Range" /> : <Progress id="progress" text="Uploading..." loadingPercentage={loadingPercentage.toFixed(2)} />}
          {base64ImageSecond ? <div>...</div> : null}
          {base64ImageSecond ? <img className="preview_image" src={`data:image/png;base64,${base64ImageSecond}`} alt="Pdf Peview Second of Range" /> : null}
        </div>
      </div>
    </div>
  );
}

export default PDFPreview;