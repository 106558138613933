import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import FormPDF from "./FormPDF";

function PdfToImg() {


    return (
        <div className="App">
          <title>PDF to Image Converter - Turn PDFs into High-Quality Images for Free | PNG, JPEG, GIF, TIFF</title>
          <Header/>
          <FormPDF/>
          <Footer/>
        </div>
    );
}

export default PdfToImg;