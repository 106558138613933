import React from "react";
import '../styles/Main.css';

function Others() {
    return (
        <div className='others'>
            <div className='others_buttons'>
                <a className='ot_button' id="faq_ot" href="/faq"></a>
                <label className='ot_label'>FAQ's</label>
            </div>
            <div className='others_buttons'>
                <a className='ot_button' id="about_us_ot" href="/about-us"></a>
                <label className='ot_label'>About Us</label>
            </div>
        </div>
    );
}

export default Others;