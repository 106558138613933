import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import FormPDF from "./FormPDF";

function Split() {
  return (
    <div>
      <title>Split PDFs in Seconds - Fast and Free Online PDF Splitter</title>
      <Header/>
      <FormPDF/>
      <Footer/>
    </div>
  );
}

export default Split;