import React, { useEffect, useState } from "react";
import pdf_to_img from '../../img/pdf-to-img.svg';
import pdf_to_docx from '../../img/pdf-to-docx.svg';
import pdf_to_scan from '../../img/pdf-to-scan.svg';
import SelectToolButton from "./SelectToolButton";
import '../styles/Row.css';


function FromPDFRow() {

    const [overflow, setOverflow] = useState(false);

    const leftArrowClick = () => {
        let scrollDiv = document.getElementById('scroll_div_from');
        scrollDiv.scrollTo({
            left: scrollDiv.scrollLeft - 150,
            behavior: 'smooth'
        });
    }
    const rightArrowClick = () => {
        let scrollDiv = document.getElementById('scroll_div_from');
        scrollDiv.scrollTo({
            left: scrollDiv.scrollLeft + 150,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        let scrollDive = document.getElementById('scroll_div_from');
        let selec_div = document.getElementById('selection_div_from');
        if (scrollDive.scrollWidth > selec_div.scrollWidth) {
            setOverflow(true);
        } else {
            setOverflow(false);
        }
    }, []);

    return (
        <div className='pdf_row_div'>
            <label className='pdf_label'>Convert From PDF</label>
            <div className='selection_div' id="selection_div_from">
                <div className='scrollable_div' id="scroll_div_from">
                    <SelectToolButton
                        label='PDF to IMG'
                        source={pdf_to_img}
                        altText="Convert from PDF to IMG"
                        link='/pdf-to-img'
                    />
                    <SelectToolButton
                        label='PDF to WORD'
                        source={pdf_to_docx}
                        altText="Convert from PDF to WORD"
                        link='/pdf-to-docx'
                    />
                    <SelectToolButton
                        label='PDF to SCAN'
                        source={pdf_to_scan}
                        altText="Convert from PDF to SCAN"
                        link='/pdf-to-scan'
                    />

                </div>
                {overflow && <button className="leftArrow" onClick={leftArrowClick}></button>}
                {overflow && <button className="rightArrow" onClick={rightArrowClick}></button>}
            </div>
        </div>
    );
}

export default FromPDFRow;