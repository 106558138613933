import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import FormPDF from "./FormPDF";

function Merge() {

  return (
    <div className="App">
      <title>Merge PDFs Easily and Free with Our Online Tool</title>
      <Header />
      <FormPDF />
      <Footer />
    </div>
  );
}

export default Merge;