import React, { useState, useRef } from 'react';
import { PDFDocument } from 'pdf-lib';
import "../../styles/FormPDF.css";
import Progress from '../../components/Progress';
import SelectPropertiesPDF from './SelectPropertiesPDF';
import PDFPreview from './PDFPreview';
import axios from 'axios';
import 'react-tippy/dist/tippy.css'; // Import Tippy.js styles
import 'tippy.js/animations/scale.css'; // Import Tippy.js animation styles
import { Tooltip } from 'react-tippy';
import "../../styles/Dropzone.css";
import UploadForm from '../../components/UploadForm';



const API_BASE_URL = 'https://onlysplitpdf-75429c6816ab.herokuapp.com';

function FormPDF(props) {

  const title = "Split PDF's";
  const loading_text = "Splitting PDF...";

  const [splitPdfs, setSplitPdfs] = useState();
  const fileInputRef = useRef(null);

  const [loadingPercentage, setLoadingPercentage] = useState(0);



  const handlePDFProcessing = async (ranges) => {
    const file = fileInputRef.current.files[0];
    try {

      const response = await axios.post(`${API_BASE_URL}/api/process-pdf`, {
        pdf_file: file,
        name: file.name,
        ranges: ranges
      },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setLoadingPercentage(progress);
          },
          responseType: 'blob',
        });
      console.log(response.data);
      setSplitPdfs(response.data);
    } catch (error) {
      console.error('Error processing PDF:', error);
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const [fileName, setFileName] = useState("");

  const [controlButtonReset, setControlButtonReset] = useState(false);

  const [controlSplitting, setControlSplitting] = useState(true);

  const [pages, setPages] = useState([]);

  const [pdf, setPDF] = useState();

  const [pdfPreviewList, setPdfPreviewList] = useState([]);

  const [divZone, setDivZone] = useState(false);


  function resetDropzone() {
    setSelectedFile(null);
    setFileName("");
    hideControlButtonReset();
    setControlSplitting(true);
  };

  function hideControlButtonReset() {
    setControlButtonReset(false);
  };

  const createPDFPreview = (key, previewFirst, previewSecond) => {
    setPdfPreviewList((prevList) => {
      const preview = {
        key: key,
        id: key,
        pdf: pdf,
        previewFirst: previewFirst,
        previewSecond: previewSecond,
      };
      return [...prevList, preview];
    });
  };

  const updatePDFPreviewFromValue = (id, fromValue) => {
    const new_pdfpreview_list = pdfPreviewList.map((preview) => {
      if (preview.key === id) {
        return { ...preview, previewFirst: parseInt(fromValue) - 1 };
      }
      return preview;
    });
    setPdfPreviewList(new_pdfpreview_list);
  };

  const updatePDFPreviewToValue = (id, toValue) => {
    const new_pdfpreview_list = pdfPreviewList.map((preview) => {
      if (preview.key === id) {
        return { ...preview, previewSecond: parseInt(toValue) - 1 };
      }
      return preview;
    });
    setPdfPreviewList(new_pdfpreview_list);
  }

  const updatePDFPreviewBothValues = (id, fromValue, toValue) => {
    const new_pdfpreview_list = pdfPreviewList.map((preview) => {
      if (preview.key === id) {
        return { ...preview, previewFirst: parseInt(fromValue) - 1, previewSecond: parseInt(toValue) - 1 };
      }
      return preview;
    });
    setPdfPreviewList(new_pdfpreview_list);
  }

  const fileUploaded = async (event) => {

    if (event.target.files[0].type === 'application/pdf') {
      setControlSplitting(false);
      const file = event.target.files[0];
      setPDF(file);
      const pdfBuffer = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBuffer);
      setPages(pdfDoc.getPages());
      setDivZone(true);
    }
  }

  const [fileNoDropped, setFileNoDropped] = useState(true);

  const handleDivClick = async () => {
    document.getElementById("fileInput_split").click();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const dropzone = document.getElementById('dropzone_split');
    dropzone.classList.remove('dragging');
    const fileInput = document.getElementById("fileInput_split");
    if (e.dataTransfer.types.includes('Files')) {
      if (e.dataTransfer.files[0].type === 'application/pdf') {
        const fileList = new DataTransfer();
        fileList.items.add(e.dataTransfer.files[0]);
        fileInput.files = fileList.files;
        const changeEvent = new Event('change', { bubbles: true });
        fileInput.dispatchEvent(changeEvent);
        setFileNoDropped(false);
      }
    }
  }



  const handleDragOver = (e) => {
    e.preventDefault();
    const dropzone = document.getElementById('dropzone_split');
    dropzone.classList.add('dragging');
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    const dropzone = document.getElementById('dropzone_split');
    dropzone.classList.remove('dragging');
  };

  const [splitting, setSplitting] = useState(false);
  const [donwloadControl, setDonwloadControl] = useState(false);

  const splitClick = async (divisionsList) => {
    setDivZone(false);
    setSplitting(true);
    var ranges = "";
    divisionsList.map((division) => {
      if (division.fromValue === division.toValue) {
        ranges += division.fromValue + ",";
      } else {
        ranges += division.fromValue + "-" + division.toValue + ",";
      }
    })
    ranges = ranges.slice(0, -1);
    await handlePDFProcessing(ranges).then(() => {
      setSplitting(false);
      setDonwloadControl(true);
    });
  }

  const handleReload = () => {
    window.location.reload();
  };

  const clickDownload = () => {
    document.getElementById("download_tag").click();
  };

  const deletAllPDFPreview = () => {
    setPdfPreviewList(pdfPreviewList.filter((preview) => null));
  }

  const deletePDFPreview = (id) => {
    const new_pdfpreview_list = pdfPreviewList.filter((preview) => preview.key !== id);
    setPdfPreviewList(new_pdfpreview_list);
  };

  const [pdfBase64, setPdfBase64] = useState([]);

  const savePdfBase64 = (base64, page) => {
    setPdfBase64((prevList) => {
      const aux_base64 = {
        base64: base64,
        page: page
      }
      return [...prevList, aux_base64];
    });
  };

  const getPdfBase64 = (page) => {
    const aux_base64 = pdfBase64.filter((item) => item.page === page);
    try {
      return aux_base64[0].base64;
    } catch (error) {
      return null;
    }
  }

  const onClickShareLink = (link) => {
    window.open(link, '_blank');
  }

  return (
    <div>
      <div id="dropzone_split" className="dropzone" onDrop={fileNoDropped ? handleDrop : null} onDragOver={fileNoDropped ? handleDragOver : null} onDragLeave={fileNoDropped ? handleDragLeave : null}>
        {donwloadControl ?
          <div className="download_pdf">
            <UploadForm
              url="/split"
              handleDivClick={clickDownload}
              title="Enjoy your PDF file!"
              description="You can download your PDF files splitted by clicking on the button below."
              button="Download PDF"
              behindButton=""
            />
            <a
              id="download_tag"
              className="download_tag"
              href={window.URL.createObjectURL(new Blob([splitPdfs], { type: "application/zip" }))}
              download={"only_split_pdf.zip"}
            />
            <a className="reload_tag" href="#" onClick={handleReload}>Split more PDF's</a>
          </div> : null
        }

        {splitting && <Progress id="progress" text={loading_text} loadingPercentage={loadingPercentage.toFixed(0)} />}
        {controlSplitting ?
          <UploadForm
            url="/split"
            handleDivClick={handleDivClick}
            title="Split PDF's"
            description="Divide a page or an entire set to simplify the process of splitting in individual PDF files. Enhance the organization of your PDF documents quickly and easily. Merge mutiple PDF's online for free with our easy-to-use tool. Combine multiple PDF files seamlessly and efficiently."
            button="Select PDF Files"
            behindButton="or drop PDF files here"
          />
          : null}
        <input
          id="fileInput_split"
          type="file"
          accept=".pdf"
          ref={fileInputRef}
          onChange={fileUploaded}
          hidden
        />

        {divZone ?
          <div className='div_preview'>
            <div className="pdf_preview_list_title">
              <div className='pdf_preview_title'>
                <h1>{title}</h1>
              </div>
              <div className='pdf_preview_list'>
                {pdfPreviewList.map((newPDFPreview) =>
                  <div onDragStart={(e) => e.preventDefault()}>
                    <PDFPreview
                      key={newPDFPreview.key}
                      id={newPDFPreview.key}
                      pdf={newPDFPreview.pdf}
                      previewFirst={newPDFPreview.previewFirst}
                      previewSecond={newPDFPreview.previewSecond}
                      savePdfBase64={savePdfBase64}
                      pdfBase64={pdfBase64}
                      getPdfBase64={getPdfBase64}
                    />
                  </div>
                )}
              </div>

            </div>
            <SelectPropertiesPDF pdf={pdf} pages={pages} createPDFPreview={createPDFPreview} updatePDFPreviewFromValue={updatePDFPreviewFromValue} updatePDFPreviewToValue={updatePDFPreviewToValue} updatePDFPreviewBothValues={updatePDFPreviewBothValues} splitClick={splitClick} deletAllPDFPreview={deletAllPDFPreview} deletePDFPreview={deletePDFPreview} />
          </div> : null}

        {selectedFile && (
          <div>
            <h2>Archivo PDF con páginas impares:</h2>
            <a href={selectedFile} download={fileName}>
              Descargar PDF con páginas impares
            </a>
            {controlButtonReset && <button id="control_button" onClick={resetDropzone} />}
          </div>
        )}

      </div>
      <div className="ad_banner" id="ad_banner_dropzone">
      </div>
    </div>
  );
}

export default FormPDF;